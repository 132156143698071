/* eslint-disable @typescript-eslint/no-explicit-any */
// db.ts
import { Dexie, Table, UpdateSpec } from "dexie";
import {
  DatabaseConfig,
  GenerateStoreSchemaParams,
  IDbHandlerService,
  RecordObject,
} from "./db.service.interface";
import { Injectable } from "@angular/core";
import Schemas from "../../../assets/schemas/schema.json";
import { OrganizationModule } from "../models/view-models/organization";

@Injectable({
  providedIn: "root",
})
export class AppDBService extends Dexie implements IDbHandlerService {
  private db!: Dexie;
  dataTables: { [key: string]: Table<any, number> } = {};
  private initialized = false;
  private tableSchemas = Schemas;

  constructor() {
    super("LocalIndexedDB");
  }

  initialize() {
    if (this.initialized) {
      return;
    }
    try {
      const config = this.generateConfigFromSchemas(this.tableSchemas);
      this.db = new Dexie(config.dbName);
      this.db.version(config.version).stores(config.stores);
      for (const tableName in config.stores) {
        this.dataTables[tableName] = this.db.table(tableName);
      }
      this.db.on("populate", () => {
        return this.populate();
      });
      this.initialized = true;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  }

  async populate(populateData?: (db: IDbHandlerService) => Promise<void>) {
    if (populateData) {
      await populateData(this);
    }
  }

  generateConfigFromSchemas(schemas: {
    [tableName: string]: any;
  }): DatabaseConfig {
    const stores: { [tableName: string]: string } = {};
    for (const tableName in schemas) {
      stores[tableName] = this.generateStoreSchema(schemas[tableName]);
    }
    return {
      dbName: "LocalIndexedDB",
      version: 1,
      stores,
    };
  }

  generateStoreSchema(obj: GenerateStoreSchemaParams): string {
    const primaryKeyFieldName = obj.PrimaryKey;
    const otherFields = Object.keys(obj).filter((field) => {
      return field !== primaryKeyFieldName && field !== "PrimaryKey";
    });
    return [`++${primaryKeyFieldName}`, ...otherFields].join(", ");
  }

  async addRecord<U>(tableName: string, record: U) {
    try {
      return await this.dataTables[tableName].add(record);
    } catch (e: Error | DOMException | unknown) {
      if (e instanceof DOMException && e.name === "ConstraintError") {
        throw new Error(JSON.stringify(e));
      } else {
        throw new Error(JSON.stringify(e));
      }
    }
  }

  async getRecord<U>(
    tableName: string,
    columnName?: string,
    matchingValue?: string | number,
  ): Promise<U | undefined> {
    const table = this.dataTables[tableName];
    if (!table) {
      throw new Error(`Table ${tableName} does not exist`);
    }

    let collection: Dexie.Collection<U, number>;

    if (columnName && columnName !== undefined) {
      collection = table
        .where(columnName)
        .equals(matchingValue!) as Dexie.Collection<U, number>;
    } else {
      collection = table.toCollection() as Dexie.Collection<U, number>;
    }

    const data = await collection.first();
    return data as U | undefined;
  }

  getRecords(tableName: string) {
    return this.dataTables[tableName].toArray();
  }

  async getPaginatedRecords(
    tableName: string,
    query: (table: Table<any, any>) => any,
    pageIndex: number = 0,
    noOfRecords: number = 10,
  ) {
    try {
      const table = this.dataTables[tableName];
      if (!table) throw new Error(`Table ${tableName} does not exist`);

      const offset = pageIndex * noOfRecords;
      return await query(table).offset(offset).limit(noOfRecords).toArray();
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  }

  async updateRecord(
    tableName: string,
    id: number,
    changes:
      | UpdateSpec<RecordObject>
      | ((
          obj: RecordObject,
          ctx: { value: RecordObject; primKey: number },
        ) => boolean | void),
  ) {
    return await this.dataTables[tableName].update(id, changes);
  }

  async updateRecordWithWhere(
    tableName: string,
    columnName: string,
    matchingValue: string,
    changes:
      | UpdateSpec<any>
      | ((obj: any, ctx: { value: any; primKey: any }) => boolean | void),
  ) {
    return await this.dataTables[tableName]
      .where(columnName)
      .equals(matchingValue)
      .modify(changes);
  }

  async deleteRecord(tableName: string, id: number) {
    return await this.dataTables[tableName].delete(id);
  }

  async truncateTable(tableName: string) {
    await this.dataTables[tableName].clear();
  }

  async resetDatabase(populateData?: (db: AppDBService) => Promise<void>) {
    for (const tableName in this.dataTables) {
      await this.dataTables[tableName].clear();
    }
    if (populateData) {
      await populateData(this);
    }
  }

  flattenOrganizationModules(
    nestedArray: OrganizationModule[],
  ): OrganizationModule[] {
    const flatArray: OrganizationModule[] = [];
    this.recurse(nestedArray, flatArray);
    return flatArray;
  }

  private recurse(
    array: OrganizationModule[],
    flatArray: OrganizationModule[],
  ) {
    for (const item of array) {
      flatArray.push({ ...item }); // Add current item to the flat array
      if (item.children && item.children.length > 0) {
        this.recurse(item.children, flatArray); // Recur for children
      }
    }
  }

  findDefaultModule(
    allModules: OrganizationModule[],
  ): OrganizationModule | undefined {
    for (const module of allModules) {
      if (module.IsDefault) {
        return module; // Return the route if it is the default
      }
      if (module.children && module.children.length > 0) {
        const defaultChildRoute = this.findDefaultModule(module.children);
        if (defaultChildRoute) {
          return defaultChildRoute; // Return the actual child route that is default
        }
      }
    }
    return undefined;
  }
}
