import { Routes } from "@angular/router";
import { ComingSoonComponent } from "../../components/coming-soon/coming-soon.component";
import { PageNotFoundComponent } from "../../components/page-not-found/page-not-found.component";
import { LoadingLandingPageComponent } from "../../components/loading-landing-page/loading-landing-page.component";

export function getDefaultRoutes(): Routes {
  const allDefaultRoutes: Routes = [];
  allDefaultRoutes.push({
    path: "coming-soon",
    component: ComingSoonComponent,
  });
  allDefaultRoutes.push({
    path: "404",
    component: PageNotFoundComponent,
  });
  allDefaultRoutes.push({
    path: "**",
    component: LoadingLandingPageComponent,
  });
  return allDefaultRoutes;
}
