import {
  HttpHeaders,
  HttpInterceptorFn,
  HttpRequest,
} from "@angular/common/http";
import { finalize } from "rxjs/operators";
import { inject } from "@angular/core";
import { Auth, getAuth, onAuthStateChanged } from "@angular/fire/auth";
import { from, Observable, switchMap } from "rxjs";

// Server Response Time Interceptor
export const responseTimeInterceptorFunctional: HttpInterceptorFn = (
  req,
  next,
) => {
  // const startTime = Date.now();
  return next(req).pipe(
    finalize(() => {
    }),
  );
};

const refreshToken = (auth: Auth): Observable<string> => {
  return from(
    auth.currentUser ? auth.currentUser.getIdToken(true) : Promise.resolve(""),
  );
};

// Function to add token to request
const addTokenToRequest = <T>(
  req: HttpRequest<T>,
  token: string,
): HttpRequest<T> => {
  // const cookieService = inject(CookieService);
  return req.clone({
    headers: new HttpHeaders({
      Authorization: `Bearer ${token}`,
      // SubDomain: cookieService.get("subDomain") || ""
    }),
  });
};

// Interceptor function
export const authInterceptorFunctional: HttpInterceptorFn = (req, next) => {
  const auth = inject(Auth);

  const getIdToken = (): Promise<string> => {
    return new Promise((resolve) => {
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          user.getIdToken().then((idToken) => {
            try {
              return resolve(idToken);
            } catch (err) {
              throw new Error(JSON.stringify(err));
            }
          });
        } else {
          return resolve("");
        }
      });
    });
  };

  // Fetch the current token or force refresh if necessary
  return from(getIdToken()).pipe(
    switchMap((token) => {
      // Clone the request and add the token
      const authReq = addTokenToRequest(req, token);

      // Return the request with the token and proceed
      return refreshToken(auth).pipe(
        switchMap((newToken) => {
          const updatedReq = addTokenToRequest(authReq, newToken);
          return next(updatedReq);
        }),
      );
    }),
  );
};
