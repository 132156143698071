import { Routes } from "@angular/router";
import { PageNotFoundComponent } from "../components/page-not-found/page-not-found.component";
import { ComingSoonComponent } from "../components/coming-soon/coming-soon.component";
import { inject, Injectable } from "@angular/core";
import {
  ModuleOperation,
  OrganizationModule,
} from "../shared/models/view-models/organization";
import {
  ModuleCoreURL,
  ModuleOperationEnum,
} from "../helpers/enums/modules.enums";
import { AppDBService } from "../shared/services/db.service";
import { CookieService } from "ngx-cookie-service";
import { SignInRequiredComponent } from "../components/sign-in-required/sign-in-required.component";

@Injectable({
  providedIn: "root",
})
export class RouteConfiguration {
  private dbService: AppDBService = inject(AppDBService);
  private cookieService: CookieService = inject(CookieService);

  /**
   * Processes the routes configured
   * @param configuredOrganizationModules OPTIONAL param: Send list of organization modules, if null, it will pick from local DB
   * @returns Promise<Routes>
   */
  async getConfiguredRoutes(
    configuredOrganizationModules?: OrganizationModule[],
  ): Promise<Routes> {
    const allConfiguredRoutes: Routes = [];

    //-- Filling data from local indexed db if not passed
    if (
      !configuredOrganizationModules ||
      (configuredOrganizationModules &&
        configuredOrganizationModules.length == 0)
    ) {
      configuredOrganizationModules = (await this.dbService.getRecord(
        "OrganizationModule",
      )) as OrganizationModule[];
    }

    allConfiguredRoutes.push({
      path: "",
      loadComponent: () => {
        return import("../components/common/common.component").then((m) => {
          return m.CommonComponent;
        });
      },
      children: await this.getConfiguredChildRoutes(
        configuredOrganizationModules,
      ),
    });

    allConfiguredRoutes.push({
      path: "coming-soon",
      component: ComingSoonComponent,
    });

    allConfiguredRoutes.push({
      path: "sign-in-required",
      component: SignInRequiredComponent,
    });

    allConfiguredRoutes.push({
      path: "404",
      component: PageNotFoundComponent,
    });

    allConfiguredRoutes.push({
      path: "**",
      component: PageNotFoundComponent,
    });
    return allConfiguredRoutes;
  }

  /*
    To check if the user is sign in, redundant definition to avoid circular dependency
  */
  isUserSignedIn() {
    const userHasAccessToken = this.cookieService.get("accessToken");
    if (userHasAccessToken) {
      this.cookieService.set("isUserGuest", "false");
      this.cookieService.set("isUserVisitor", "false");
    }
    return userHasAccessToken ? true : false;
  }

  async getConfiguredChildRoutes(
    configuredOrganizationModules: OrganizationModule[],
  ): Promise<Routes> {
    const allConfiguredChildRoutes: Routes = [];

    if (
      configuredOrganizationModules &&
      configuredOrganizationModules.length > 0
    ) {
      //-- Assign default route - Starts
      const defaultModule: OrganizationModule | undefined =
        this.dbService.findDefaultModule(configuredOrganizationModules);

      if (defaultModule) {
        allConfiguredChildRoutes.push({
          path: "",
          pathMatch: "full",
          redirectTo: defaultModule?.ModuleURL,
        });
      } else {
        allConfiguredChildRoutes.push({
          path: "",
          pathMatch: "full",
          loadComponent: () => {
            return import(
              "../components/landing-page/landing-page.component"
            ).then((m) => {
              return m.LandingPageComponent;
            });
          },
        });
      }
      //-- Assign default route - Ends

      //-- Add these routes only if the user is signed in - Starts
      if (this.isUserSignedIn()) {
        allConfiguredChildRoutes.push({
          path: "profile/:visitingUserUUId",
          loadComponent: () => {
            return import("../components/profile/profile.component").then(
              (m) => {
                return m.ProfileComponent;
              },
            );
          },
        });
        allConfiguredChildRoutes.push({
          path: "profile-edit/:visitingUserUUId",
          loadComponent: () => {
            return import(
              "../components/profile-edit/profile-edit.component"
            ).then((m) => {
              return m.ProfileEditComponent;
            });
          },
        });
        //!-- Temp-module Remove this later
        allConfiguredChildRoutes.push({
          path: "temp-wr",
          loadComponent: () => {
            return import("../components/temp-wr/temp-wr.component").then(
              (m) => {
                return m.TempWRComponent;
              },
            );
          },
        });
        //!-- Temp-module Remove this later
        allConfiguredChildRoutes.push({
          path: "temp-wr-detail",
          loadComponent: () => {
            return import(
              "../components/temp-wr-detail/temp-wr-detail.component"
            ).then((m) => {
              return m.TempWRDetailComponent;
            });
          },
        });
      }
      //-- Add these routes only if the user is signed in - Ends

      const flattenedOrganizationModules: OrganizationModule[] =
        this.dbService.flattenOrganizationModules(
          configuredOrganizationModules,
        );

      for (const organizationModule of flattenedOrganizationModules) {
        switch (organizationModule.ModuleCoreURL) {
          case ModuleCoreURL.HOME: {
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL,
              loadComponent: () => {
                return import(
                  "../components/landing-page/landing-page.component"
                ).then((m) => {
                  return m.LandingPageComponent;
                });
              },
            });
            break;
          }
          case ModuleCoreURL.ORGANIZATION: {
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL,
              loadComponent: () => {
                return import(
                  "../components/organization/organization.component"
                ).then((m) => {
                  return m.OrganizationComponent;
                });
              },
            });
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL + "/new",
              loadComponent: () => {
                return import(
                  "../components/organization-details/organization-details.component"
                ).then((m) => {
                  return m.OrganizationDetailsComponent;
                });
              },
            });
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL + "/:organizationUUID",
              loadComponent: () => {
                return import(
                  "../components/organization-details/organization-details.component"
                ).then((m) => {
                  return m.OrganizationDetailsComponent;
                });
              },
            });
            break;
          }
          case ModuleCoreURL.DEPARTMENTS: {
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL,
              loadComponent: () => {
                return import(
                  "../components/department/pages/department-list/department-list.component"
                ).then((m) => {
                  return m.DepartmentListComponent;
                });
              },
            });
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL + "/new",
              loadComponent: () => {
                return import(
                  "../components/department/pages/department-details/department-details.component"
                ).then((m) => {
                  return m.DepartmentDetailsComponent;
                });
              },
            });
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL + "/:departmentUUID",
              loadComponent: () => {
                return import(
                  "../components/department/pages/department-details/department-details.component"
                ).then((m) => {
                  return m.DepartmentDetailsComponent;
                });
              },
              children: [
                {
                  path: "about",
                  loadComponent: () => {
                    return import(
                      "../components/department/components/department-info/department-info.component"
                    ).then((m) => {
                      return m.DepartmentInfoComponent;
                    });
                  },
                },
                {
                  path: "members",
                  loadComponent: () => {
                    return import(
                      "../components/department/components/department-members/department-members.component"
                    ).then((m) => {
                      return m.DepartmentMembersComponent;
                    });
                  },
                },
                {
                  path: "reviews",
                  loadComponent: () => {
                    return import(
                      "../components/department/components/department-reviews/department-reviews.component"
                    ).then((m) => {
                      return m.DepartmentReviewsComponent;
                    });
                  },
                },
              ],
            });
            break;
          }
          case ModuleCoreURL.MODULES: {
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL,
              loadComponent: () => {
                return import("../components/modules/modules.component").then(
                  (m) => {
                    return m.ModulesComponent;
                  },
                );
              },
            });
            allConfiguredChildRoutes.push({
              path:
                organizationModule.ModuleURL +
                "/:organizationUUID/:organizationModuleUUID",
              loadComponent: () => {
                return import(
                  "../components/modules/edit-modules/edit-modules.component"
                ).then((m) => {
                  return m.EditModulesComponent;
                });
              },
            });
            break;
          }
          case ModuleCoreURL.USERS: {
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL,
              loadComponent: () => {
                return import("../components/user/user.component").then((m) => {
                  return m.UserComponent;
                });
              },
            });
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL + "/invites",
              loadComponent: () => {
                return import(
                  "../components/user/invites/invites.component"
                ).then((m) => {
                  return m.InvitesComponent;
                });
              },
            });
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL + "/invites/send-invite",
              loadComponent: () => {
                return import(
                  "../components/user/invites/send-invite/send-invite.component"
                ).then((m) => {
                  return m.SendInviteComponent;
                });
              },
            });
            break;
          }
          case ModuleCoreURL.USER_ROLES: {
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL,
              loadComponent: () => {
                return import(
                  "../components/user-roles/user-roles.component"
                ).then((m) => {
                  return m.UserRolesComponent;
                });
              },
            });
            break;
          }
          case ModuleCoreURL.MESSAGE_BOARD: {
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL,
              loadComponent: () => {
                return import(
                  "../components/message-board/message-board.component"
                ).then((m) => {
                  return m.MessageBoardComponent;
                });
              },
            });
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL + "/new",
              loadComponent: () => {
                return import(
                  "../components/message-board/add-message/add-message.component"
                ).then((m) => {
                  return m.AddMessageComponent;
                });
              },
            });
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL + "/:messageUUID",
              loadComponent: () => {
                return import(
                  "../components/message-board/add-message/add-message.component"
                ).then((m) => {
                  return m.AddMessageComponent;
                });
              },
            });
            break;
          }
          case ModuleCoreURL.CATEGORY: {
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL,
              loadComponent: () => {
                return import(
                  "../components/category/category-list/category-list.component"
                ).then((m) => {
                  return m.CategoryListComponent;
                });
              },
            });
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL + "/new",
              loadComponent: () => {
                return import(
                  "../components/category/category-details/category-details.component"
                ).then((m) => {
                  return m.CategoryDetailsComponent;
                });
              },
            });
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL + "/:categoryUUID",
              loadComponent: () => {
                return import(
                  "../components/category/category-details/category-details.component"
                ).then((m) => {
                  return m.CategoryDetailsComponent;
                });
              },
            });
            break;
          }
          case ModuleCoreURL.ASSET: {
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL,
              loadComponent: () => {
                return import(
                  "../components/asset/pages/asset-list/asset-list.component"
                ).then((m) => {
                  return m.AssetListComponent;
                });
              },
            });
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL + "/:assetUUID",
              loadComponent: () => {
                return import(
                  "../components/asset/pages/asset-detail/asset-detail.component"
                ).then((m) => {
                  return m.AssetDetailComponent;
                });
              },
              children: [
                // { path: "", redirectTo: "info", pathMatch: "full" },
                {
                  path: "info",
                  loadComponent: () => {
                    return import(
                      "../components/asset/components/asset-info/asset-info.component"
                    ).then((m) => {
                      return m.AssetInfoComponent;
                    });
                  },
                },
                {
                  path: "additional-data",
                  loadComponent: () => {
                    return import(
                      "../components/asset/components/asset-dynamic-keys-controls/asset-dynamic-keys-controls.component"
                    ).then((m) => {
                      return m.AssetDynamicKeysControlsComponent;
                    });
                  },
                },
                {
                  path: "documents",
                  loadComponent: () => {
                    return import(
                      "../components/asset/components/documents-attachments/documents-attachments.component"
                    ).then((m) => {
                      return m.DocumentsAttachmentsComponent;
                    });
                  },
                },
                {
                  path: "meters",
                  loadComponent: () => {
                    return import(
                      "../components/asset/components/meters/meters.component"
                    ).then((m) => {
                      return m.MetersComponent;
                    });
                  },
                },
                {
                  path: "transactions",
                  loadComponent: () => {
                    return import(
                      "../components/asset/components/transactions/transactions.component"
                    ).then((m) => {
                      return m.TransactionsComponent;
                    });
                  },
                },
                {
                  path: "custody",
                  loadComponent: () => {
                    return import(
                      "../components/asset/components/custody/custody.component"
                    ).then((m) => {
                      return m.CustodyComponent;
                    });
                  },
                },
                {
                  path: "sub-assets",
                  loadComponent: () => {
                    return import(
                      "../components/asset/components/sub-assets/sub-assets.component"
                    ).then((m) => {
                      return m.SubAssetsComponent;
                    });
                  },
                },
                {
                  path: "history",
                  loadComponent: () => {
                    return import(
                      "../components/asset/components/history/history.component"
                    ).then((m) => {
                      return m.HistoryComponent;
                    });
                  },
                },
              ],
            });
            break;
          }

          case ModuleCoreURL.TASKS: {
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL,
              loadComponent: () => {
                return import(
                  "../components/work-order/pages/work-order-list/work-order-list.component"
                ).then((m) => {
                  return m.WorkOrderListComponent;
                });
              },
            });
            allConfiguredChildRoutes.push({
              path: `${organizationModule.ModuleURL}/create`,
              loadComponent: () => {
                return import(
                  "../components/work-order/pages/work-order-list/work-order-list.component"
                ).then((m) => {
                  return m.WorkOrderListComponent;
                });
              },
            });
            allConfiguredChildRoutes.push({
              path: `${organizationModule.ModuleURL}/edit/:encodedWorkOrderUUID`,
              loadComponent: () => {
                return import(
                  "../components/work-order/pages/work-order-list/work-order-list.component"
                ).then((m) => {
                  return m.WorkOrderListComponent;
                });
              },
            });
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL + "/:encodedWorkOrderUUID",
              loadComponent: () => {
                return import(
                  "../components/work-order/pages/work-order-detail/work-order-detail.component"
                ).then((m) => {
                  return m.WorkOrderDetailComponent;
                });
              },
            });
            break;
          }

          case ModuleCoreURL.REQUESTS: {
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL,
              loadComponent: () => {
                return import(
                  "../components/work-request/pages/work-request-list/work-request-list.component"
                ).then((m) => {
                  return m.WorkRequestListComponent;
                });
              },
            });
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL + "/create",
              loadComponent: () => {
                return import(
                  "../components/work-request/pages/work-request-list/work-request-list.component"
                ).then((m) => {
                  return m.WorkRequestListComponent;
                });
              },
            });
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL + "/:encodedWorkRequestUUID",
              loadComponent: () => {
                return import(
                  "../components/work-request/pages/work-request-detail/work-request-detail.component"
                ).then((m) => {
                  return m.WorkRequestDetailComponent;
                });
              },
            });
            break;
          }

          case ModuleCoreURL.TASK_MANAGEMENT: {
            const hasWOReadPermission =
              await this.checkIfUserHasReadModulePermission(
                ModuleCoreURL.TASKS,
              );
            if (hasWOReadPermission) {
              allConfiguredChildRoutes.push({
                path: organizationModule.ModuleURL,
                loadComponent: () => {
                  return import(
                    "../components/work-order/pages/work-order-list/work-order-list.component"
                  ).then((m) => {
                    return m.WorkOrderListComponent;
                  });
                },
              });
            } else {
              const hasWRReadPermission =
                await this.checkIfUserHasReadModulePermission(
                  ModuleCoreURL.REQUESTS,
                );
              if (hasWRReadPermission) {
                allConfiguredChildRoutes.push({
                  path: organizationModule.ModuleURL,
                  loadComponent: () => {
                    return import(
                      "../components/work-request/pages/work-request-list/work-request-list.component"
                    ).then((m) => {
                      return m.WorkRequestListComponent;
                    });
                  },
                });
              }
            }
            break;
          }

          case ModuleCoreURL.EVENTS:
          case ModuleCoreURL.HISTORY:
          case ModuleCoreURL.STORE:
          case ModuleCoreURL.ORDERS:
          case ModuleCoreURL.REPORTS:
          case ModuleCoreURL.SERVICES:
          case ModuleCoreURL.TERMS_OF_SERVICES:
          case ModuleCoreURL.PRIVACY_POLICY:
          case ModuleCoreURL.HELP:
          case ModuleCoreURL.SETTINGS:
          case ModuleCoreURL.ADMIN:
          case ModuleCoreURL.CHAT:
          case ModuleCoreURL.CUSTOM_PAGE:
          case ModuleCoreURL.USER_DEFINED_PAGE: {
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL,
              loadComponent: () => {
                return import(
                  "../components/coming-soon/coming-soon.component"
                ).then((m) => {
                  return m.ComingSoonComponent;
                });
              },
            });
            break;
          }
          default: {
            allConfiguredChildRoutes.push({
              path: organizationModule.ModuleURL,
              loadComponent: () => {
                return import(
                  "../components/page-not-found/page-not-found.component"
                ).then((m) => {
                  return m.PageNotFoundComponent;
                });
              },
            });
            break;
          }
        }
      }
    }

    allConfiguredChildRoutes.push({
      path: "coming-soon",
      component: ComingSoonComponent,
    });
    //!-- Temp-preview Remove this later
    allConfiguredChildRoutes.push({
      path: "preview",
      loadComponent: () => {
        return import(
          "../components/designer-ui-preview/designer-ui-preview.component"
        ).then((m) => {
          return m.DesignerUiPreviewComponent;
        });
      },
    });

    allConfiguredChildRoutes.push({
      path: "404",
      component: PageNotFoundComponent,
    });

    allConfiguredChildRoutes.push({
      path: "**",
      component: PageNotFoundComponent,
    });
    return allConfiguredChildRoutes;
  }

  async checkIfUserHasReadModulePermission(
    moduleCoreEnum: ModuleCoreURL,
  ): Promise<boolean> {
    const flattenedOrganizationModules: OrganizationModule[] =
      (await this.dbService.getRecord(
        "FlattenedOrganizationModule",
      )) as OrganizationModule[];
    const configuredModule = flattenedOrganizationModules.find(
      (organizationModule: OrganizationModule) => {
        return organizationModule.ModuleCoreURL == moduleCoreEnum;
      },
    );
    if (
      configuredModule?.ModuleOperations &&
      configuredModule?.ModuleOperations.length > 0
    ) {
      const readOperation: ModuleOperation | undefined =
        configuredModule?.ModuleOperations.find((x) => {
          return (
            x.ModuleOperation == ModuleOperationEnum.READ &&
            x.IsPermitted == true
          );
        });
      if (readOperation) {
        return true;
      }
    }
    return false;
  }
}
