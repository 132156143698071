import { inject, Injectable } from "@angular/core";
import { Location } from "@angular/common";
import { ICommonService } from "./common.service.interface";
import { CookieService } from "ngx-cookie-service";
import { Router, Routes } from "@angular/router";
import {
  ModuleOperation,
  Organization,
  OrganizationDetailsByOrgURL,
  OrganizationModule,
} from "../../shared/models/view-models/organization";
import { AppDBService } from "../../shared/services/db.service";
import { EventEmitterService } from "../../shared/services/event-emitter.service";
import { ResponseHelper } from "../../helpers/response-helper";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environment/environment";
import { finalize, Observable } from "rxjs";
import { APIURLs } from "../../helpers/api-urls";
import { RouteConfiguration } from "../../initialization/app.routing";
import { DeviceDetectorService } from "ngx-device-detector";
import {
  ModuleOperationEnum,
  ModuleCoreURL,
} from "../../helpers/enums/modules.enums";
import { AngularFireStorage } from "@angular/fire/compat/storage";

@Injectable({
  providedIn: "root",
})
export class CommonService implements ICommonService {
  public cookieService: CookieService = inject(CookieService);
  public router: Router = inject(Router);
  public dbService: AppDBService = inject(AppDBService);
  public eventEmitterService: EventEmitterService = inject(EventEmitterService);
  public http: HttpClient = inject(HttpClient);
  private routeConfiguration: RouteConfiguration = inject(RouteConfiguration);
  public location = inject(Location);
  private deviceDetectorService: DeviceDetectorService = inject(
    DeviceDetectorService,
  );
  private firebaseStorage = inject(AngularFireStorage);
  organizationDetails: OrganizationDetailsByOrgURL = {};

  async signOut() {
    await this.dbService.truncateTable("OrganizationModule");
    await this.dbService.truncateTable("FlattenedOrganizationModule");
    await this.dbService.truncateTable("User");
    this.setUserAsVisitor();
    this.eventEmitterService.requestNewRoutesAndModules();
  }

  isUserSignedIn(): boolean {
    const userHasAccessToken = this.cookieService.get("accessToken");
    if (userHasAccessToken) {
      this.cookieService.set("isUserGuest", "false");
      this.cookieService.set("isUserVisitor", "false");
    }
    return userHasAccessToken ? true : false;
  }

  getUserUUID(): string {
    return this.cookieService.get("userUUID");
  }

  setUserSignInCookies(
    idTokenFromFB: string,
    refreshToken: string,
    userUUID: string,
  ): void {
    this.cookieService.set("accessToken", idTokenFromFB);
    this.cookieService.set("refreshToken", refreshToken);
    this.cookieService.set("userUUID", userUUID);
  }

  isUserAGuestUser(): boolean {
    return this.cookieService.get("isUserGuest") == "true" ? true : false;
  }

  setUserAsGuest(): void {
    this.cookieService.delete("isUserVisitor");
    this.cookieService.delete("userUUID");
    this.cookieService.delete("accessToken");
    this.cookieService.delete("refreshToken");
    this.cookieService.set("isUserGuest", "true");
  }

  isUserAVisitor(): boolean {
    return this.cookieService.get("isUserVisitor") == "true" ? true : false;
  }

  setUserAsVisitor(): void {
    this.cookieService.delete("isUserGuest");
    this.cookieService.delete("isUserVisitor");
    this.cookieService.delete("userUUID");
    this.cookieService.delete("accessToken");
    this.cookieService.delete("refreshToken");
    this.cookieService.set("isUserVisitor", "true");
  }

  async getOrganizationUUID(): Promise<string> {
    const organizationDetails =
      await this.dbService.getRecord<Organization>("Organization");
    if (organizationDetails && organizationDetails.OrganizationUUID) {
      return organizationDetails.OrganizationUUID;
    }
    return "";
  }

  getOrganizationDetailsByOrgURL(organizationURL: string) {
    // Initialize HttpParams
    let params = new HttpParams();
    // Add query parameters conditionally if they are not null
    if (organizationURL) {
      params = params.set("organizationURL", organizationURL);
    }
    return this.http.get(
      `${environment.rootAPIURL + APIURLs.GETORGANIZATIONDETAILSBYORGURL}`,
      { params },
    );
  }

  getOrganizationBasedModules(
    userUUID: string | null,
    organizationUUID: string | null,
  ): Observable<ResponseHelper> {
    // Initialize HttpParams
    let params = new HttpParams();
    // Add query parameters conditionally if they are not null
    if (userUUID) {
      params = params.set("userUUID", userUUID);
    }
    if (organizationUUID) {
      params = params.set("organizationUUID", organizationUUID);
    }
    return this.http.get<ResponseHelper>(
      `${environment.rootAPIURL + APIURLs.GETORGANIZATIONBASEDMODULES}`,
      { params },
    );
  }

  getURAndOrganizationBasedModules(
    userRoleType: string | null,
    organizationUUID: string | null,
  ): Observable<ResponseHelper> {
    return this.http.post<ResponseHelper>(
      environment.rootAPIURL + APIURLs.GETURANDORGANIZATIONBASEDMODULES,
      {
        userRoleType: userRoleType,
        organizationUUID: organizationUUID,
      },
    );
  }

  async addOrganizationModulesToIndexedDB(
    organizationModuleList: OrganizationModule[],
  ) {
    await this.dbService.truncateTable("OrganizationModule");
    await this.dbService.truncateTable("FlattenedOrganizationModule");
    await this.dbService.addRecord<OrganizationModule[]>(
      "OrganizationModule",
      organizationModuleList,
    );
    const flattenedOrganizationModules =
      this.dbService.flattenOrganizationModules(organizationModuleList);
    await this.dbService.addRecord<OrganizationModule[]>(
      "FlattenedOrganizationModule",
      flattenedOrganizationModules,
    );
  }

  async resetRoutesAndPermissions(
    allConfiguredModules?: OrganizationModule[],
  ): Promise<Routes> {
    const allConfiguredRoutes: Routes =
      await this.routeConfiguration.getConfiguredRoutes(allConfiguredModules);
    this.router.resetConfig(allConfiguredRoutes);
    const pathAfterDomain = this.location.path();
    this.router.navigateByUrl(pathAfterDomain || "/");
    this.eventEmitterService.markNewRoutesForApplicationLoaded();
    return allConfiguredRoutes;
  }

  // Function to extract the path after the domain
  extractPath(): string {
    const domainIndex = this.router.url.indexOf("/", 0); // Find the index of the first `/`
    return domainIndex !== -1 ? this.router.url.substring(domainIndex) : "/";
  }

  getCurrentLanguage(): string | null {
    return this.cookieService.get("translate_language");
  }

  isDeviceDesktop(): boolean {
    return this.deviceDetectorService.isDesktop();
  }

  async getModuleRoute(
    moduleCoreEnum: ModuleCoreURL,
  ): Promise<string | null | undefined> {
    const flattenedOrganizationModules = (await this.dbService.getRecord(
      "FlattenedOrganizationModule",
    )) as OrganizationModule[];
    const configuredModule = flattenedOrganizationModules.find(
      (organizationModule: OrganizationModule) => {
        return organizationModule.ModuleCoreURL == moduleCoreEnum;
      },
    );
    return configuredModule?.ModuleURL;
  }

  async getModuleName(
    moduleCoreEnum: ModuleCoreURL,
  ): Promise<string | null | undefined> {
    const flattenedOrganizationModules = (await this.dbService.getRecord(
      "FlattenedOrganizationModule",
    )) as OrganizationModule[];
    const configuredModule = flattenedOrganizationModules.find(
      (organizationModule: OrganizationModule) => {
        return organizationModule.ModuleCoreURL == moduleCoreEnum;
      },
    );
    return configuredModule?.ModuleName;
  }

  async checkIfUserHasReadModulePermission(
    moduleCoreEnum: ModuleCoreURL,
  ): Promise<boolean> {
    const flattenedOrganizationModules: OrganizationModule[] =
      (await this.dbService.getRecord(
        "FlattenedOrganizationModule",
      )) as OrganizationModule[];
    const configuredModule = flattenedOrganizationModules.find(
      (organizationModule: OrganizationModule) => {
        return organizationModule.ModuleCoreURL == moduleCoreEnum;
      },
    );
    if (
      configuredModule?.ModuleOperations &&
      configuredModule?.ModuleOperations.length > 0
    ) {
      const readOperation: ModuleOperation | undefined =
        configuredModule?.ModuleOperations.find((x) => {
          return (
            x.ModuleOperation == ModuleOperationEnum.READ &&
            x.IsPermitted == true
          );
        });
      if (readOperation) {
        return true;
      }
    }
    return false;
  }

  async checkIfUserHasCreateOrUpdateModulePermission(
    moduleCoreEnum: ModuleCoreURL,
  ): Promise<boolean> {
    const flattenedOrganizationModules: OrganizationModule[] =
      (await this.dbService.getRecord(
        "FlattenedOrganizationModule",
      )) as OrganizationModule[];
    const configuredModule = flattenedOrganizationModules.find(
      (organizationModule: OrganizationModule) => {
        return organizationModule.ModuleCoreURL == moduleCoreEnum;
      },
    );
    let isCreatePermitted: boolean = false,
      isEditPermitted: boolean = false;
    if (
      configuredModule?.ModuleOperations &&
      configuredModule?.ModuleOperations.length > 0
    ) {
      for (
        let moduleIndex = 0;
        moduleIndex < configuredModule?.ModuleOperations.length;
        moduleIndex++
      ) {
        const moduleOperation: ModuleOperation =
          configuredModule?.ModuleOperations[moduleIndex];
        switch (moduleOperation.ModuleOperation) {
          case ModuleOperationEnum.CREATE:
            {
              isCreatePermitted =
                moduleOperation.IsPermitted == true ? true : false;
            }
            break;
          case ModuleOperationEnum.UPDATE:
            {
              isEditPermitted =
                moduleOperation.IsPermitted == true ? true : false;
            }
            break;
        }
      }
      return isCreatePermitted && isEditPermitted;
    }
    return false;
  }

  async checkIfUserHasDeleteModulePermission(
    moduleCoreEnum: ModuleCoreURL,
  ): Promise<boolean> {
    const flattenedOrganizationModules: OrganizationModule[] =
      (await this.dbService.getRecord(
        "FlattenedOrganizationModule",
      )) as OrganizationModule[];
    const configuredModule = flattenedOrganizationModules.find(
      (organizationModule: OrganizationModule) => {
        return organizationModule.ModuleCoreURL == moduleCoreEnum;
      },
    );
    if (
      configuredModule?.ModuleOperations &&
      configuredModule?.ModuleOperations.length > 0
    ) {
      const readOperation: ModuleOperation | undefined =
        configuredModule?.ModuleOperations.find((x) => {
          return (
            x.ModuleOperation == ModuleOperationEnum.DELETE &&
            x.IsPermitted == true
          );
        });
      if (readOperation) {
        return true;
      }
    }
    return false;
  }

  uploadFile(
    file: File,
    folderName: string,
  ): {
    percentage: Observable<number | undefined>;
    downloadURL: Observable<string>;
  } {
    const fileName: string = `${new Date().getTime().toString()}_${file.name}`;
    const filePath = `uploads/${folderName}/${fileName}`;
    const fileRef = this.firebaseStorage.ref(filePath);

    const metadata = {
      contentType: file.type,
    };

    const task = this.firebaseStorage.upload(filePath, file, metadata);

    const percentage = task.percentageChanges();

    const downloadURL = new Observable<string>((observer) => {
      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            fileRef.getDownloadURL().subscribe({
              next: (url) => {
                observer.next(url);
                observer.complete();
              },
              error: (err) => {
                observer.error(err);
              },
            });
          }),
        )
        .subscribe();
    });

    return { percentage, downloadURL };
  }

  getDownloadFileURL(fileName: string, folderName: string): Observable<string> {
    const filePath = `uploads/${folderName}/${fileName}`;
    const fileRef = this.firebaseStorage.ref(filePath);
    return fileRef.getDownloadURL();
  }
}
