import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { initFlowbite } from "flowbite";
import { AppService } from "./app.service";
import {
  Organization,
  OrganizationDetailsByOrgURL,
  OrganizationModule,
} from "../shared/models/view-models/organization";
import { Title } from "@angular/platform-browser";
import { ResponseCode, ResponseHelper } from "../helpers/response-helper";
import { LoadingLandingPageComponent } from "../components/loading-landing-page/loading-landing-page.component";
import { Subscription } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
  standalone: true,
  imports: [RouterOutlet, TranslateModule, LoadingLandingPageComponent],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  showLoadingCommunityPage: boolean = false;
  subDomainDataManipulationSubscription: Subscription;
  loadNewRoutesAndModulesSubscription: Subscription;

  constructor(
    private appService: AppService,
    private titleService: Title,
    private router: Router,
  ) {
    this.appService.setDefaultLanguage();
    this.appService.dbService.initialize();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }

  ngOnInit(): void {
    this.setThemeFromPreviousConfiguration();
    this.appService.registerToastListener();
    this.getAndSetSubdomainInCookies();
    this.listenToNewRouteLoadRequest();
  }

  ngAfterViewInit() {
    initFlowbite();
  }

  listenToNewRouteLoadRequest() {
    this.loadNewRoutesAndModulesSubscription =
      this.appService.eventEmitterService.loadNewRoutesAndModules.subscribe(
        async () => {
          this.showLoadingCommunityPage = true;
          const organizationDetails: Organization =
            (await this.appService.dbService.getRecord(
              "Organization",
            )) as Organization;
          //-- Load dynamic routes configured for the organization
          this.loadRoutesAndModules(
            organizationDetails?.OrganizationUUID || null,
          );
        },
      );
  }

  getAndSetSubdomainInCookies() {
    const { hostname } = window.location;
    const parts = hostname.split(".");

    // Assume that if there are more than 2 parts, the first part is the subdomain
    let subDomain: string = "harmonic";
    if (parts.length > 2) {
      if (isNaN(parseInt(parts[0]))) {
        subDomain = parts[0] as string;
      }
    }
    const existingSubdomain = this.appService.cookieService.get("subDomain");
    if (!existingSubdomain || existingSubdomain !== subDomain) {
      //-- Verify, set and get sub-domain details
      this.listenToSubDomainDataChange();
      this.appService.getOrganizationDetailsBySubdomain(subDomain);
    } else {
      //-- Sub-domain same as before so don't call an API to verify sub-domain
      this.showLoadingCommunityPage = true;
      this.setUserItsUserRoleType();
    }
  }

  setThemeFromPreviousConfiguration() {
    // if set via local storage previously
    const currentTheme: string | null = localStorage.getItem("color-theme");
    if (currentTheme != null) {
      if (currentTheme) {
        if (currentTheme === "light") {
          document.documentElement.classList.remove("dark");
          this.updateFavicon("light");
        } else {
          document.documentElement.classList.add("dark");
          this.updateFavicon("dark");
        }
      }
    } else {
      //-- This sets light theme as default
      document.documentElement.classList.remove("dark");
      this.updateFavicon("light");
    }
  }

  private updateFavicon(themeName: string) {
    const favicon = document.getElementById("favicon") as HTMLLinkElement;
    if (favicon) {
      favicon.href = `assets/images/favicon/favicon-${themeName}.ico`;
    }
  }

  listenToSubDomainDataChange() {
    this.showLoadingCommunityPage = true;
    this.subDomainDataManipulationSubscription =
      this.appService.eventEmitterService.subDomainDataManipulation.subscribe(
        (organizationDetails: OrganizationDetailsByOrgURL) => {
          if (organizationDetails) {
            //-- Sub domain is valid
            this.setUserItsUserRoleType();
          } else {
            //-- Sub domain is invalid
            this.showLoadingCommunityPage = false;
            this.appService.cookieService.delete("subDomain");
            this.appService.dbService.truncateTable("Organization");
            this.appService.router.navigate(["/404"]);
          }
        },
      );
  }

  async setUserItsUserRoleType() {
    const organizationDetails: Organization =
      (await this.appService.dbService.getRecord(
        "Organization",
      )) as Organization;
    if (organizationDetails) {
      this.addDynamicPageTitle(organizationDetails?.OrganizationTitleText);
      //-- Load dynamic routes configured for the organization
      this.loadRoutesAndModules(organizationDetails?.OrganizationUUID || null);
    } else {
      //-- Set variables for 404 page
      this.show404Page();
    }
  }

  /**
   * Changes browser title for the app
   * @param organizationTitleText Title text configured in database
   * @returns void
   */

  addDynamicPageTitle(organizationTitleText?: string | null) {
    //-- Dynamic page title
    this.titleService.setTitle(
      organizationTitleText || this.titleService.getTitle(),
    );
  }

  /**
   * Loads the routes for any  user.
   * @param organizationUUID: Organization UUID to which user is visitor | guest | signed in
   * @returns void
   */
  async loadRoutesAndModules(organizationUUID: string | null): Promise<void> {
    try {
      let responseFromAPI: ResponseHelper | undefined;

      if (this.appService.isUserSignedIn()) {
        // User is signed in
        responseFromAPI =
          await this.appService.getRoutesAndModulesForSignedInUser(
            this.appService.getUserUUID(),
            organizationUUID,
          );
      } else if (this.appService.isUserAGuestUser()) {
        // User is a guest
        responseFromAPI =
          await this.appService.getRoutesAndModulesForGuest(organizationUUID);
      } else {
        // First-time visiting user
        this.appService.setUserAsVisitor();
        responseFromAPI =
          await this.appService.getRoutesAndModulesForVisitor(organizationUUID);
      }

      // Ensure that responseFromAPI is valid before proceeding
      if (responseFromAPI) {
        this.postOrganizationModulesFetchOperation(responseFromAPI);
      } else {
        console.error(
          "Failed to load routes and modules: No response from API",
        );
      }
    } catch (error) {
      console.error("Error loading routes and modules:", error);
      // Handle or throw the error accordingly
    }
  }

  async postOrganizationModulesFetchOperation(
    response: ResponseHelper | undefined,
  ) {
    if (response && response.Status == ResponseCode.SUCCESS) {
      const allConfiguredModules: OrganizationModule[] =
        response.Payload as OrganizationModule[];
      if (allConfiguredModules) {
        await this.appService.addOrganizationModulesToIndexedDB(
          allConfiguredModules,
        );
        this.appService.resetRoutesAndPermissions(allConfiguredModules);
        this.showLoadingCommunityPage = false;
      } else {
        this.show404Page();
      }
      // const defaultModule: OrganizationModule | undefined =
      //   this.appService.dbService.findDefaultModule(allConfiguredModules);
      // this.showLoadingCommunityPage = false;
      // if (defaultModule) {
      //   this.appService.router.navigateByUrl(
      //     "/" + defaultModule.ModuleURL || "home",
      //   );
      // }
    } else {
      this.show404Page();
    }
  }

  show404Page() {
    //-- Set variables for 404 page
    this.showLoadingCommunityPage = false;
    this.appService.setUserAsVisitor();
    this.appService.router.navigate(["/404"]);
  }

  ngOnDestroy(): void {
    if (this.subDomainDataManipulationSubscription)
      this.subDomainDataManipulationSubscription.unsubscribe();
    if (this.loadNewRoutesAndModulesSubscription)
      this.loadNewRoutesAndModulesSubscription.unsubscribe();
  }
}
