import { bootstrapApplication } from "@angular/platform-browser";
import { AppConfig } from "./app/initialization/app.config";
import { AppComponent } from "./app/initialization/app.component";
import { environment } from "./environment/environment";
import { enableProdMode } from "@angular/core";

if (environment.production === "true") {
  enableProdMode();
}

bootstrapApplication(AppComponent, AppConfig).catch((err) => {
  return console.error(err);
});
