import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  provideZoneChangeDetection,
} from "@angular/core";

import { environment } from "../../environment/environment";
import { ROUTES } from "@angular/router";
import { provideLottieOptions } from "ngx-lottie";

import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
} from "@angular/common/http";

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";

import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import {
  BrowserAnimationsModule,
  provideAnimations,
} from "@angular/platform-browser/animations";
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";

import { getStorage, provideStorage } from "@angular/fire/storage";

import { provideAuth, getAuth } from "@angular/fire/auth";

import { provideFirestore, getFirestore } from "@angular/fire/firestore";

import { FIREBASE_OPTIONS } from "@angular/fire/compat";
import { GlobalErrorHandlerService } from "../shared/services/global-error-handler.service";
import {
  responseTimeInterceptorFunctional,
  authInterceptorFunctional,
} from "../interceptors/auth.interceptor";
import { provideNgxMask } from "ngx-mask";
import { BrowserModule } from "@angular/platform-browser";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import {
  LazyLoadImageModule,
  LAZYLOAD_IMAGE_HOOKS,
  ScrollHooks,
} from "ng-lazyload-image";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";

import { provideToastr } from "ngx-toastr";
import { getDefaultRoutes } from "../shared/factories/get-default-routes";

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export const provideTranslation = () => {
  return {
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient],
    },
  };
};

export const AppConfig: ApplicationConfig = {
  providers: [
    {
      provide: ROUTES,
      useFactory: getDefaultRoutes,
      multi: false,
    },
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideFirebaseApp(() => {
      return initializeApp(environment.firebaseConfig);
    }),
    provideStorage(() => {
      return getStorage();
    }),
    provideAuth(() => {
      return getAuth();
    }),
    provideHttpClient(
      withInterceptors([
        responseTimeInterceptorFunctional,
        authInterceptorFunctional,
      ]),
    ),
    provideFirestore(() => {
      return getFirestore();
    }),
    provideAnimations(), // required animations providers
    provideToastr({
      timeOut: 10000,
      positionClass: "toast-top-right",
    }),
    provideNgxMask(),
    provideAnimationsAsync(),
    importProvidersFrom([
      TranslateModule.forRoot(provideTranslation()),
      BrowserModule,
      BrowserAnimationsModule,
      NgxSkeletonLoaderModule,
      LazyLoadImageModule,
    ]),
    {
      provide: FIREBASE_OPTIONS,
      useValue: environment.firebaseConfig,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
    provideLottieOptions({
      player: () => {
        return import("lottie-web");
      },
    }),
  ],
};
